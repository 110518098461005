/* eslint-disable react/no-danger */
import React from 'react';
import Head from 'next/head';

import { BrandContext } from './BrandContext';

// a wrapper to allow setting the brands themes by overriding common places
// to avoid us having to pass around the brand variables everywhere
// once we upgrade MUI we should have an opportunity to use css variables which would make this much simpler

const BrandThemes = React.memo(({ children, brand }) => {
  const fontColor = brand?.fontColor || '#FFFFFF';
  const { orgColor, avatarUri, botAvatarUrl, secondaryColor } = brand;

  const avatar = botAvatarUrl || avatarUri; // because we need to consolidate the "brandService" logic we have in functions (which does this already) to also be in API

  return (
    <>
      <Head>
        {orgColor && orgColor !== '#999999' && (
          <style>{`
                    .siteback {
                        background-color: ${orgColor} !important;
                    }
                    .sitebackUnder {
                        background-color: ${orgColor}30 !important;
                    }
                    .MuiButton-containedPrimary {
                        background-color: ${orgColor} !important;
                        color: ${fontColor} !important;
                    }
                    .MuiButton-outlinedPrimary {
                      border-color: ${orgColor} !important;
                      color: ${orgColor} !important;

                    }
                    .MuiButton-outlinedPrimary:hover {
                      background-color: ${orgColor} !important;
                      color: #FFFFFF !important;
                    }
                   .Mui-disabled{
                      background-color: #b1b1b1 !important;
                      color: #FFFFFF !important;
                    }
                    .job-list-title {
                       color: ${orgColor} !important;
                    }
                    .chattrtar_container {
                      border: 1px solid ${orgColor} !important
                    }
                `}</style>
        )}
        {avatar && (
          <style>
            {`
              .chattrtar {
                    background-image: url('${avatar}')
                    }
            `}
          </style>
        )}
        {secondaryColor && secondaryColor !== '#999999' && (
          <style>
            {`
               .MuiButton-containedSecondary {
                    border: 1px solid ${secondaryColor} !important;
                    background-color: #fff !important;
                    color: ${secondaryColor} !important;
                    -webkit-font-smoothing: antialiased;
                    font-style: normal;
                }
            `}
          </style>
        )}
      </Head>

      <BrandContext.Provider value={brand}>{children}</BrandContext.Provider>
    </>
  );
});

export default BrandThemes;
