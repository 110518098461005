import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { unescape } from 'lodash';

import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

const SEO = React.memo(({ currentUrl, org, brand, job, keywords = null }) => {
  const logoUrl = brand?.logoUrl;

  const [pageTitle, setPageTitle] = useState(`Careers with ${org.name}`);
  const [pageDescription, setPageDescription] = useState('');
  const companyName = job?.location?.brand?.name || job?.location?.companyName || org.name;

  function stripHtml(html) {
    if (typeof document !== 'undefined') {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || '';
    }
    return html;
  }

  // if keywords are set on the job, use those, otherwise dynamically compile some keywords
  if (job) {
    if (!job.keywords) {
      keywords += `${companyName.split(' ').join(',')},`;
      keywords += `${job.title.split(' ').join(',')},`;
      keywords += `${job.category.name},${job.type},`;
      keywords += `${job.location.city},${job.location.state}`;
    } else {
      keywords = job.keywords;
    }
  }

  useEffect(() => {
    if (job) {
      setPageTitle(
        `${job.title} Needed - ${job.location.city}, ${job.location.state} | ${companyName}`,
      );
      setPageDescription(
        `${job.title} needed for positions with ${companyName} in ${job.location.city}, ${job.location.state}`,
      );
    } else {
      const cleanedDescription = unescape(stripHtml(brand?.description || org?.description || ''));
      setPageDescription(cleanedDescription);
    }

    if (org.googleTagManagerId && org.googleTagManagerId.length > 0) {
      // console.log(`GTM Init ${org.googleTagManagerId}`);
      try {
        TagManager.initialize({ gtmId: org.googleTagManagerId });
      } catch (error) {
        console.log(error);
      }
    }
    if (org.googleAnalyticsId && org.googleAnalyticsId.length > 0) {
      // console.log(`GA Init ${org.googleAnalyticsId}`);
      try {
        ReactGA.initialize(org.googleAnalyticsId);
        ReactGA.pageview(window.location.pathname + window.location.search);
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  return (
    <Head>
      {pageTitle && (
        <title key="title" itemProp="name">
          {pageTitle}
        </title>
      )}

      {pageDescription && <link rel="canonical" href={currentUrl} />}

      {pageDescription && (
        <meta
          key="description"
          name="description"
          property="og:description"
          content={pageDescription}
        />
      )}
      {pageDescription && (
        <meta key="description2" itemProp="description" content={pageDescription} />
      )}

      {logoUrl && <meta key="image" name="image" content={logoUrl} />}
      {keywords && (
        <meta key="keywords" name="keywords" property="og:keywords" content={keywords} />
      )}

      {pageTitle && <meta key="ogTitle" property="og:title" content={pageTitle} />}
      {currentUrl && <meta key="ogUrl" property="og:url" content={currentUrl} />}
      {currentUrl && <meta key="url" itemProp="url" content={currentUrl} />}

      {org.facebookDomainVerificationCode && org.facebookDomainVerificationCode.length > 0 && (
        <meta
          key="facebook-domain-verification"
          name="facebook-domain-verification"
          content={`${org.facebookDomainVerificationCode}`}
        />
      )}
    </Head>
  );
});

export default SEO;
