/* eslint-disable react/no-danger */
import React from 'react';
import Head from 'next/head';

import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import pkg from '../../package.json';

import Chattrtar from '../Chattrtar';

const version = pkg.version || null;

const useStyles = makeStyles(() => ({
  loadingBarBackground: {
    backgroundColor: ({ color }) => `${color}40`, // 40% opacity
  },
  loadingBarForeground: {
    backgroundColor: ({ color }) => color,
  },
}));

// SBHVT note added brand and unique avatars here
const PageWrapper = React.memo(
  ({
    children,
    brandName,
    logoUrl,
    uniqueAvatars,
    showHeaderAvs,
    isLoading,
    color = '#30C67C',
  }) => {
    const myClasses = useStyles({
      color,
    });

    return (
      <>
        <div className="content">
          <div className="siteback" />
          <div className="sitebackUnder" />

          <div className="centerIt">
            <div className="logoContainer">
              {logoUrl ? (
                <img alt={`${brandName} Company Logo`} className="logo" src={logoUrl} />
              ) : (
                <div>{brandName}</div>
              )}
              {/*
            <div className="avatarContainer">
              {showHeaderAvs &&
                uniqueAvatars &&
                uniqueAvatars.length > 0 &&
                uniqueAvatars.map((x) => {
                  return <Chattrtar avatarUri={x} key={x} />;
                })}
            </div>
            */}
            </div>
          </div>

          {isLoading && (
            <div className="loadingBarWrapper">
              <LinearProgress
                classes={{
                  colorPrimary: myClasses.loadingBarBackground,
                  barColorPrimary: myClasses.loadingBarForeground,
                }}
              />
            </div>
          )}

          {children}

          <div className="version">{version}</div>
        </div>
        <Head>
          <script
            dangerouslySetInnerHTML={{
              __html: `
                            (function(h,o,t,j,a,r){
                                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                h._hjSettings={hjid:2218257,hjsv:6};
                                a=o.getElementsByTagName('head')[0];
                                r=o.createElement('script');r.async=1;
                                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                a.appendChild(r);
                            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                        `,
            }}
          />
        </Head>
      </>
    );
  },
);

export default PageWrapper;
