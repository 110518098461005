import React from 'react';

import Chattrtar from '../Chattrtar';

const BlockHeader = ({ tagLine, children, uniqueAvatars, showHeaderAvs, brand }) => {
  const heroUri = `url("${brand.themeHeroUri}")`;

  return (
    <div>
      <div className="block-header">
        <div className="block-header-content">
          <div className="block-header-info">
            <div className="block-header-logo-container filter-card">
              <div className="block-header-logo">
                <img alt="" src={brand.logoUrl} />
              </div>
              <div className="centerIt">
                <div className="avatarContainer">
                  {showHeaderAvs &&
                    uniqueAvatars &&
                    uniqueAvatars.length > 0 &&
                    uniqueAvatars.map((x) => {
                      return <Chattrtar avatarUri={x} key={x} />;
                    })}
                </div>
              </div>
            </div>
            {tagLine && <div className="block-header-tagline">{tagLine}</div>}
            <div className="block-header-filter-container">{children}</div>
          </div>
        </div>
        <div
          className="block-header-content block-header-hero"
          style={{
            backgroundImage: heroUri,
          }}
        />
      </div>
    </div>
  );
};

export default BlockHeader;
