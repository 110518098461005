import React from 'react';
import Head from 'next/head';

const LoadScript = React.memo(({ url, isRecruitics = false, isAppCast = false, org = {} }) => {
  return (
    <Head>
      <script key="chattr" src={url} onLoad={`window.chattr._init();console.log('init-loaded')`} />

      {isRecruitics && (
        <script
          key="recruitics"
          src="https://jsv3.recruitics.com/partner/c6ded56d-d44b-11eb-af98-1f6f8673f32f.js"
        />
      )}
      {isAppCast && (
        <script
          key="appcast-jobview"
          type="text/javascript"
          src={`https://click.appcast.io/pixels/homegrown1-${org.appCastClientId}.js?ent=${
            org.appCastAccountType || '417'
          }`}
        />
      )}
    </Head>
  );
});

export default LoadScript;
