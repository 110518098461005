import React from 'react';

const Chattrtar = ({ avatarUri, size = 'large' }) => {
  const myAvatarUri = avatarUri;

  const containerClass = size === 'small' ? 'chattrtar_container_small' : 'chattrtar_container';
  const innerClass = size === 'small' ? 'chattrtar_small' : 'chattrtar';

  const output = myAvatarUri ? (
    <div className="jobAvatar">
      <div className={containerClass}>
        <div
          className={innerClass}
          style={{
            backgroundImage: `url("${myAvatarUri}")`,
          }}
        />
      </div>
    </div>
  ) : null;

  return output;
};

export default Chattrtar;
