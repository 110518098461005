import React from 'react';

import Container from '@material-ui/core/Container';

import Chattrtar from '../Chattrtar';

const HeroHeader = ({ tagLine, uniqueAvatars, showHeaderAvs, brand }) => {
  const heroUri = `url("${brand.themeHeroUri}")`;

  return (
    <div>
      <Container maxWidth="lg">
        <div className="hero-header-top">
          <div style={{ float: 'left' }}>
            <img className="hero-header-logo" alt="" src={brand.logoUrl} />
          </div>

          <div style={{ float: 'right' }}>
            <div className="avatarContainer">
              {showHeaderAvs &&
                uniqueAvatars &&
                uniqueAvatars.length > 0 &&
                uniqueAvatars.map((x) => {
                  return <Chattrtar avatarUri={x} key={x} />;
                })}
            </div>
          </div>
        </div>
      </Container>

      <div className="hero-header-bg" style={{ backgroundImage: heroUri }}>
        {tagLine && (
          <Container maxWidth="lg">
            <div className="hero-header-title">{tagLine}</div>
          </Container>
        )}
      </div>
    </div>
  );
};

export default HeroHeader;
